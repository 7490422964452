@import "/src/scss/variables";
@import "/src/scss/mixins";

.button-input {
  margin-bottom: 15px;
  .hidden { display: none; }
  .bp3-form-group { margin-bottom: 2px; }
  .bp3-input-group { width: auto; min-width: 300px; margin-right: 5px; }
  .bp3-button { margin: 0; }

  span.message {
    font-size: 0.8rem;
    color: theme-color("secondary");

    &.error { color: theme-color("error") }
  }
}
