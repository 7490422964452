.accordion {
    margin-bottom: 15px;
}

.accordion-title {
    margin-left: 12px;
}

.title-row {
    display: flex;
    cursor: pointer;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.05);
}

.expand-button.bp3-button {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
}

.accordion-content {
    margin-top: 12px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 100ms;

    &.open {
      max-height: 1500px;
      transition: max-height 800ms;
    }
}
