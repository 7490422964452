@import "/src/scss/_variables";

div.resend-verification-banner {
  position: fixed;
  top: $headerMenuHeight;
  width: 100%;
  z-index: 2;

  ~ div.Main {
    top: $headerMenuHeight + 50px;
  }


  @media screen and (max-width: $mobileV) {
    > div.inner {
      padding: 32px 20px;
    }
  }

  > .inner {
    height: 50px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: theme-color("error-light");
    border-bottom: 4px solid theme-color("error");
  }

  button {
    border: 0;
    padding: 0;
    background: none;
    text-decoration: underline;
    cursor: pointer;
    color: theme-color("error");
    font-weight: bold;

    &:hover {
      color: theme-color("danger");
    }
  }
}
