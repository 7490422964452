.dropdownContainer {
    max-height: 250px;
    min-width: 180px;
    overflow: auto;
    position: absolute;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1);
}

.childItem {
    padding-left: 12px;
}

.parentItem {
    font-weight: bold;
}