.image-card-logo {
  max-height: 250px;
  max-width: 300px;
}

.file-upload-wrap .loading-logo {
  min-height: 220px;
}

.logo-upload-wrapper {
  background: none;
}

@keyframes fadein {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 0.9;
  }
}

.logo-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &.not-default-logo {
    .file-upload {
      width: 100%;
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }
    .drag-frame {
      display: none;
    }
    .dragging-frame {
      position: absolute;
      top: 50px;
      left: 50px;
      right: 50px;
      bottom: 50px;
      opacity: 0.9;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: fadein 200ms ease-out;
    }
  }
}
