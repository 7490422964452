.insurance-filter {
  .title > label {
    font-weight: bold;
  }
}

.insurance-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  max-height: 100px;
  transition: all 150ms ease-in;
  overflow: hidden;
  padding: 5px;
  margin: 0 -5px;

  &.hidden {
    padding: 0 5px;
    max-height: 0;
  }

  label {
    font-size: 0.8em;
  }
}

.insurance-filter-input {
  max-width: 101px;
}

.insurances-filter-form {
  padding: 8px;
}
