div.bp3-card {

    &.error, &.alert, &.success {
      margin-bottom: 10px;
      border-radius: 2px;
      padding: 20px;
    }

    h5 { margin: 0 0 5px }
    p { margin: 0; }

    svg {
      margin-right: 8px;
      font-size: 1.3em;
      vertical-align: middle;
    }

    &.alert,
    &.error {
      background-color: theme-color('error-light');
      box-shadow: none;
      color: theme-color('danger');
      svg {
        color: theme-color('danger');
      }
    }

    &.info {
      background-color: theme-color('warning-light');
      box-shadow: none;
    }

    &.success {
        background-color: theme-color('secondary-light');
        box-shadow: none;
    }

    &.inline {
        display: inline-block;
        padding: 10px 15px;
        margin: 5px;
        width: calc(25% - 10px);
        min-width: 300px;
        vertical-align: top;
    }
}
