div.bp3-form-group {
  margin-top: 5px;
}

.bp3-label {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-resize {
  resize: none;
}

.suggested-comment {
  resize: none;
  min-height: 70px;
}

div.Question {
  div.question-label {
    label.bp3-label {
      word-break: break-word;
      white-space: normal;
      line-height: 16px;
    }
  }

  div.choice .bp3-radio {
    display: block;
  }
}

label.bp3-label {
  span.bp3-popover-wrapper {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
  span.bp3-popover2-target {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.bp3-input-group {
  .bp3-numeric-input & {
    .bp3-input {
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }
  }

  .bp3-input-action,
  > .bp3-button,
  > .bp3-icon {
    display: flex;
    align-items: center;
    right: 3px !important;

    span.bp3-popover-wrapper {
      margin-right: 3px;
    }
  }

  .bp3-input-action:last-child,
  > .bp3-button:last-child,
  > .bp3-icon:last-child {
    top: 0;
    bottom: 0;
    right: 3px !important;
  }

  .inputIcon {
    position: absolute;
    top: 50%;
    left: 11px;
    transform: translateY(calc(-50% - 2px));
    display: inline-block;
    z-index: 15;
    color: #bababa;
  }
}

div.bp3-form-group.bp3-inline.form-fill {
  label.bp3-label {
    width: 290px;
    text-overflow: initial;
    line-height: 1.2 !important;
    white-space: normal;
  }

  div.bp3-form-content {
    flex: 1;
  }
}

div.FilterItem {
  div.bp3-form-group.bp3-inline.form-fill {
    label.bp3-label {
      width: auto;
    }
  }
}

div.bp3-form-content {
  div.bp3-overlay-open {
    display: block;
    height: 0;

    div.bp3-transition-container {
      position: relative !important;
      transform: unset !important;

      div.bp3-popover {
        width: 100%;
      }
    }
  }

  textarea.bp3-input {
    width: 100%;
  }
}

div.bp3-html-select.multiple {
  select {
    height: auto;
  }
}

.bp3-multi-select-popover {
  .bp3-menu {
    max-height: 300px;
    overflow: auto;
  }
}

label.bp3-control.bp3-switch {
  margin-bottom: 5px;
}

div.form-fill {
  div.bp3-form-content {
    span.bp3-popover-target {
      width: 100%;
    }
  }
}

div.bp3-control-group {
  &.bp3-fill {
    button {
      display: inline-block;
      text-align: center;
    }
  }
}

div.bp3-form-group {
  div.bp3-form-group {
    margin-top: 0;
    margin-left: 5px;
  }
}

input.bp3-input,
select.bp3-input,
textarea.bp3-input {
  &:invalid:focus {
    box-shadow: 0 0 0 1px theme-color('error'),
      0 0 0 3px rgba(theme-color('error'), 0.3),
      inset 0 1px 1px rgba(theme-color('error'), 0.2);
  }

  &:disabled {
    box-shadow: $pt-input-box-shadow;
    background-color: rgba(216, 216, 216, 0.15);
  }
}

.html-select-wrap {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: stretch;
}

:not(.bp3-fill) > .bp3-popover-target {
  padding: 0 10px;
  cursor: pointer;
}

.the-tooltip {
  display: inline-block;
  max-width: 450px;
}

.input-with-error {
  width: 100%;

  .error-message {
    display: block;
    padding-top: 5px;
    color: theme-color('error');
    font-size: 80%;

    &:empty {
      padding: 0;
    }
  }
}

.input-with-tooltip {
  display: inline-flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;
}

div.bp3-form-group {
  &.required {
    > label:not(.bp3-file-input) {
      text-decoration: dotted underline;
      text-decoration-color: theme-color('error');
      position: relative;
      padding-right: 10px;

      &::after {
        content: ' *';
        color: theme-color('error');
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

.form-error {
  color: theme-color('error');
  padding-left: 300px;
  display: block;

  .bp3-form-group + & {
    margin-top: -10px;
    margin-bottom: 15px;
  }
}

div.bp3-form-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: stretch;

  .float-right {
    margin-left: auto;
  }

  label.bp3-label {
    &.required:not(.bp3-file-input) {
      position: relative;
      text-decoration: dotted underline;
      text-decoration-color: theme-color('error');
      padding-right: 5px;

      &::after {
        content: ' *';
        color: theme-color('error');
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

div.bp3-input-group {
  width: 100%;
}

div.App[data-is-mobile='false'] {
  div.bp3-form-group.multi-inputs {
    display: flex;

    > div.bp3-label {
      flex: 0.3 1 30%;
    }

    > div.bp3-form-content {
      display: flex;

      div.bp3-input-group,
      div.bp3-button-group {
        & + div.bp3-input-group,
        & + div.bp3-button-group {
          margin-left: 5px;
        }
      }

      .bp3-progress-bar {
        flex: 0.5;

        &:first-child {
          flex: 1;
        }
      }
    }
  }

  div.form-fill {
    div.bp3-form-content {
      label.bp3-label {
        margin-left: 10px;
      }
    }
  }

  div.review-form-multi .bp3-form-content {
    display: block !important;

    div {
      margin-bottom: 10px;
    }

    label {
      margin-bottom: 5px;

      &.required {
        &::after {
          right: auto;
        }
      }
    }
  }
}
