@import "/src/scss/_variables";

.iconWrap {
  margin-left: -8px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    margin: 0 !important;
    width: 20px;
    height: 20px;
  }
}

.bp3-card.AddStockResponse {
  background: #e9f8f4;
  form {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: stretch;
  }
  .bp3-form-group {
    padding: 0 10px;
    margin: 0;
    &.text { flex: 0 1 500px }
  }
}

.bp3-card.stockResponse {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: stretch;
  
  &.inactive { background-color: #f3f3f3; }
  .bp3-form-group { padding: 0 10px 0 0; margin: 0; }
  .bp3-control { margin: 0; }
}

/* improve drag area */
.drag-wrap {
  padding: 20px;
  margin: -20px;
}

button.bp3-button.close-button {
  color: inherit;
  padding: 20px;
  margin: -20px -20px -20px auto;
  
  svg { margin: 0; }
  
  &:hover {
    color: map-get($theme, "danger");
    background: none;
  }
}
