.highlight-field .bp3-label {
    border: solid 3px red;
    display: inline-block
}

.bio-progress-container {
    display: flex;
    width: 500px;
    margin-left: 120px;
}

.bio-progress-btn {
    margin-left: 15px;
    font-size: 12px;
    text-decoration: underline;
    padding: 0;
    background: none;
    outline: none;
    border: none;
    color: rgb(32, 69, 216);

    &:hover {
        cursor: pointer;
    }
}