.bp3-menu-item {
    &.bp3-active {
        strong.highlight {
            background-color: theme-color("contrast");
            color: theme-color("dark-neutral");
        }
    }

    &:not(.bp3-active) {
        strong.highlight {
            background-color: theme-color("primary-dark");
            color: theme-color("contrast");
        }
    }
}
