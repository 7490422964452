@import "variables";
@import "mixins";

nav.menu {
  .protectedLink {
    display: block;
    line-height: 1.2;
    padding: 10px 5px;
    font-size: 1.1em;
    max-width: 100vw;
    cursor: pointer;
    color: black;
    text-transform: uppercase;
    white-space: nowrap;
    text-decoration: none;
    font-weight: 600;
    position: relative;

    &.has-count {
      padding-right: 20px;
    }

    &.active {
      color: theme-color("secondary");
    }

    &:hover {
      color: theme-color("primary");
    }
  }

  ul, li {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: block;
    position: relative;
  }

  .protectedGroup {
    .protectedItem {
      font-size: 0.8em;
    }
  }

  .protectedItem {
    display: block;

    .protectedLink-icon {
      font-size: 1.5em;
      padding: 10px;
      box-sizing: content-box;

      + .protectedLink-name {
        padding-left: 6px;
      }
    }

    .protectedLink-count {
      color: theme-color("contrast");
      background: theme-color("error");
      border: 1px solid theme-color("error-light");
      border-radius: 1000px;
      padding: 0px 5px 1px;
      font-size: 10px;
      position: absolute;
      top: -1px;
      left: calc(100% - 20px);
    }
  }
}

nav.prosure-menu {
  display: block;
  overflow: hidden;
  height: 0;
  z-index: 500;
  white-space: nowrap;

  &.show {
    overflow: visible;
    height: auto;
    align-items: center;
  }
}

nav.--desktop-menu,
nav.--options-menu {
  display: flex;

  > ul {
    display: inline-flex;
  }

  &:not(.trigger-click) li:hover > ul.protectedGroup,
  &.trigger-click.show li > ul.protectedGroup {
    opacity: 1;
    height: auto;

    .protectedLink {
      padding: 10px 20px;
    }
  }

  // added padding for mobile touch
  .is-mobile &.trigger-click.show li > ul.protectedGroup .protectedLink {
    padding: 15px 20px;
  }

  ul.protectedGroup {
    position: absolute;
    opacity: 0;
    height: 0;
    top: calc(100% - 3px);
    left: 0;
    background: white;
    overflow: hidden;
    transition: opacity 350ms $fastOutSlowInInterpolator;
    box-shadow: 0 10px 10px 0 rgba(0,0,0, 0.1);
    border-radius: 2px;

    .protectedLink {
      padding: 10px 20px 10px 15px;
      max-width: 100vw;
      transition: background-color 50ms linear,
                  color 50ms linear,
                  padding-left 550ms $fastOutSlowInInterpolator;

      &.active {
        background: theme-color("secondary-lighter");
        text-decoration: none;
      }

      &:hover {
        background: theme-color("primary-light");
        text-decoration: none;
      }
    }
  }
}

nav.--options-menu {
  height: 100%;

  &:not(.trigger-click) .options-menu-toggle:hover svg {
    background-color: #f3f3f3;
    color: theme-color("secondary");
  }

  &.trigger-click .options-menu-toggle:hover svg {
    color: theme-color("secondary");
  }

  &.trigger-click.show .options-menu-toggle svg {
    background-color: #f3f3f3;
    color: theme-color("secondary");
  }
}

nav.--mobile-menu {
  position: fixed;
  top: $headerMenuHeight;
  width: 100%;
  max-height: calc(100vh - #{$headerMenuHeight});
  background: white;
  box-shadow: 0 10px 10px 0 rgba(0,0,0, 0.1);
  transform: translateX(-5px);
  opacity: 0.8;
  transition: transform 250ms $fastOutSlowInInterpolator,
              opacity 150ms linear;

  &.show {
    border-top: 5px solid theme-color('secondary');
    transform: translateX(0);
    opacity: 1;
    overflow: auto;
  }

  li ul {
    background-color: #fafafa;
  }

  .protectedLink {
    padding: 10px 20px;

    &.active {
      font-weight: 600;
    }
  }

  ul.protectedGroup .protectedLink {
    padding-left: 40px;
  }
}

nav.--footer-menu {
  display: inline-flex;
  margin-left: auto;

  .protectedLink {
    color: white;

    &:hover {
      color: lighten(theme-color("primary"), 30%);
    }
  }

  .is-mobile & {
    .protectedLink {
      white-space: nowrap;
    }
  }
}

.prosure-menu-mobile-backdrop {
  position: fixed;
  top: $headerMenuHeight;
  bottom: auto;
  left: 0;
  right: 0;
  z-index: 400;
  background: rgba(0,0,0, 0.3);
  backdrop-filter: blur(0px); //Chrome only
  transition: all 500ms linear;

  .show-mobile-menu & {
    bottom: 0;
    backdrop-filter: blur(3px); //Chrome only
  }
}

.prosure-menu-mobile-toggle {
  border: 0;
  padding: 2px 10px 5px;
  margin: 0 10px;
  background: none;
  box-shadow: none;
  font-size: 2em;
  transition: all 150ms linear;
  cursor: pointer;

  .show-mobile-menu &,
  &:hover {
    background-color: #eee;
  }
}
