.cafm-analytics-charts {
  display: flex;
  gap: 20px;
  margin: 20px 0px 40px 0px;
}

.cafm-analytics-charts-header {
  display: flex;
  gap: 20px;
  margin: 25px 0px 20px 0px;
}

.cafm-analytics-tables {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
