.bio-label {
    margin: 0px;
    margin-right: 15px;
    font-weight: bold;
}

.progress-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 7;
    margin-top: 15px
}

.completion-text {
    margin-top: 8px;
}

.bp3-progress-bar.completion-progress > div.bp3-progress-meter {
    background-color: green;
}
