$theme: (
    'primary': #1785aa,
    'primary-dark': #00607e,
    'primary-darker': #01455a,
    'primary-light': #e9f3fa,

    'secondary': #7bbb4c,
    'secondary-dark': #548034,
    'secondary-light': #d8efc4,
    'secondary-lighter': #f6fdf6,

    'dark-neutral': #525251,
    'neutral': #929290,
    'light-neutral': #efefef,

    'warning-light': #fff3cd,
    'warning-strong': #c7b375,

    'light-neutral-overlay': #d9d7d6,

    'contrast': #ffffff,
    'subtle-background': #fefefe,

    'error': #ed5565,
    'error-light': #fcdfe2,
    'error-lighter': #fae8ea,

    'success': #0f9960,
    'success-dark': #0d8050,
    'success-darker': #0a6640,

    'danger': #a70000,
    'warning': #d9822b,
    'info': #137cbd,
    'teal': #106ba3,
);

$pageWidth: 1450px;
$headerMenuHeight: 70px;

// breakpoints
$tablet: 1248px;
$tabletV: 980px;
$mobile: 900px;
$mobileV: 690px;

// https://material.io/design/motion/speed.html#easing
$fastOutLinearInInterpolator: cubic-bezier(0.4, 0, 1, 1);
$fastOutSlowInInterpolator: cubic-bezier(0.4, 0, 0.2, 1);
$linearOutSlowInInterpolator: cubic-bezier(0, 0, 0.2, 1);
