dl.horizontal {
  dt {
    width: 20% !important;
  }

  dd {
    width: 80% !important;
  }
}

