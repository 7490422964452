@import "/src/scss/variables";

.employee-counts-tooltip-icon {
    margin-left: 8px;
}

.employee-counts-dialog {
    line-height: 18px;
    margin: 20px;
    margin-bottom: 0px;
}

.guidance {
    margin-bottom: 20px;
}


@media screen and (max-width: $mobileV) {
    .bp3-dialog.employee-counts-popup {
        width: 350px;
    }
}