  .form-card {
    &.error {
      color: #ed5565;
    }

    &.info {
      color: #87bbfd;
    }

    &.warning {
      color: #d96030;
    }
  }
