@import '/src/scss/variables';
@import '/src/scss/mixins';

.page {
  margin-top: 5vh;
  transition: all 350ms $fastOutSlowInInterpolator;

  &.page-cafm {
    margin-top: 2vh;
  }

  .page-wrapper {
    transition: height 350ms $fastOutSlowInInterpolator,
      transform 350ms $fastOutSlowInInterpolator,
      padding-top 350ms $fastOutSlowInInterpolator;
    transform: translateY(0);
    height: auto;
    padding: 20px;
    border-radius: 10px;
    margin-top: 5vh;
    background: rgba(255, 255, 255, 0.3);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.45) 0%,
      rgba(255, 255, 255, 0.9) 15vh,
      rgba(255, 255, 255, 1) 30vh,
      rgba(255, 255, 255, 1) 100%
    );
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.05);
  }

  .page-header {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    min-height: 30px;

    button + button {
      margin-left: 10px;
    }
  }

  .page-options {
    margin-left: auto;
    width: auto;
    display: flex;
    align-items: center;

    .bp3-button {
      margin: 0;

      + .bp3-button:not(.icon-only) {
        margin-left: 10px;
      }
    }

    > span {
      display: inline-block;
      padding-right: 5px;
      font-size: 1.2em;
      color: black;
      font-weight: 600;
      white-space: nowrap;
    }
  }

  .page-title {
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1;
    z-index: 1;
    padding: 0;
    margin: 0;
    color: #333;
  }
}
