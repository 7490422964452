.client-tabs div.bp3-tab-list {
    div.bp3-tab > a {
        padding: 0px 4px 0px 8px;
        margin-right: 5px;

        svg {
            margin-right: 5px;
        }
    }
}
