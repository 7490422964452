@import 'scss/variables';
@import 'scss/mixins';
@import 'scss/blueprint';

@import '/node_modules/normalize.css/normalize.css';
@import '/node_modules/react-toastify/dist/ReactToastify.min.css';

@import 'scss/preauth';
@import 'scss/header';
@import 'scss/image';
@import 'scss/main';
@import 'scss/resend-verification-banner';
// old login @import 'scss/login';
@import 'scss/register';
@import 'scss/prosure-menu';
@import 'scss/search';
@import 'scss/page';
@import 'scss/dashboard';
@import 'scss/spacings';
@import 'scss/info-card';
@import 'scss/form-card';

// new marlowe stylings
@import 'scss/marlowe/variables';
@import 'scss/marlowe/index';
@import 'scss/marlowe/box'; 
@import 'scss/marlowe/login';


html,
body,
#root {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background: #eeeceb;

    display: flex;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.branding-border {
    border-left: 7px solid theme-color('secondary');
    box-sizing: border-box;
}
.bottom-border {
    border-bottom: 7px solid theme-color('secondary');
    box-sizing: border-box;
}

div.AppContainer {
    height: 100%;
    width: 100%;
}

div.Footer {
    display: flex;
    align-items: center;
    color: #fff;

    width: 100%;

    font-size: 10px;
    padding: 0 20px;
    background: theme-color('primary-dark');

    > span {
        padding: 10px;
    }
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.cursor-as-clickable {
    cursor: pointer;
}

span {
    &.link {
        @extend .cursor-as-clickable;
        @extend a;
    }
}

.Reload {
    @extend .cursor-as-clickable;
    margin-left: 15px;
}

.full-width {
    width: 100%;
}

.text-center {
    text-align: center;
}

.LoadingIndicator {
    text-align: center;
    font-size: 2em;

    svg {
        vertical-align: 0.08em;
    }

    &[data-size='xs'] {
        svg {
            height: 0.5em;
        }
    }

    &[data-size='sm'] {
        svg {
            height: 0.8em;
        }
    }
}

.rotate45 {
    transform: rotate(45deg);
    margin: 0;
}

div.App[data-is-mobile='true'] {
    button:not(.icon-free) {
        span.bp3-button-text {
            font-size: 0;

            svg {
                font-size: initial;
            }
        }
    }
}

div.App[data-is-mobile='false'] {
    button:not(.icon-only) {
        span.bp3-button-text {
            svg {
                display: none;
            }
        }
    }
}

div.Filter {
    position: relative;
    z-index: 100;

    h1 {
        @extend .cursor-as-clickable;
    }
    div.bp3-callout {
        padding: 20px 25px 10px 20px;
        margin-bottom: 20px;
    }
    div.bp3-form-group {
        margin-bottom: 5px;
    }
    div.bp3-inline {
        align-items: center;
    }
    div.FilterRow {
        display: flex;
        justify-content: space-between;
    }
    div.FilterItem {
        padding-left: 10px;
        flex: 1;
        align-items: center;

        &:not(:last-of-type) {
            padding-right: 10px;
        }
    }
}

div.TabContent {
    padding-top: 20px;

    div.SubTabs {
        margin-top: -20px;
        padding-top: 20px;
        // background-color: theme-color("primary");
    }
}

div.ProtectedRouteItem {
    a {
        &.active {
            &:before {
                position: absolute;

                box-sizing: border-box;
                content: '';
                width: 0;
                height: 0;
                border: 50px solid transparent;
                border-bottom-width: 20px;
                border-top-width: 20px;

                border-left-width: 12px;
                border-left-style: solid;
                border-left-color: theme-color('primary');
            }
        }
    }
}

.LoadingIndicator {
    display: block;
    text-align: center;

    &.inline-block {
        display: inline-block;
        margin-left: 10px;
    }

    &[data-size='sm'] {
        height: 16px;
        font-size: 16px;
    }

    &[data-size='smd'] {
        height: 20px;
        font-size: 20px;
    }

    &[data-size='md'] {
        height: 24px;
        font-size: 24px;
    }

    &[data-size='lg'] {
        height: 36px;
        font-size: 36px;
    }

    &[data-size='xl'] {
        height: 48px;
        font-size: 48px;
    }
}

.flex {
    display: flex;
}
.flex-wrap {
    flex-wrap: wrap;
}

.flex-justify-center {
    @extend .flex;
    justify-content: center;
}

.flex-v-center {
    @extend .flex;
    align-items: center;
    width: 100%;
}

.flex-v-top {
    @extend .flex;
    align-items: flex-start;
    width: 100%;
}

.flex-start {
    @extend .flex;
    justify-content: flex-start !important;
}

.flex-end {
    @extend .flex;
    justify-content: flex-end !important;
}

.flex-v-bottom {
    @extend .flex;
    align-items: flex-end;
    width: 100%;
}

.flex-between {
    @extend .flex;
    align-items: center;
    justify-content: space-between !important;
}

.border-right-separator {
    border-right: 1px solid theme-color('dark-neutral');
}

span.success {
    color: theme-color('secondary');
}
span.warning {
    color: theme-color('warning');
}
span.error {
    color: theme-color('error');
}

.wrap-word {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
}

@for $i from 1 to 100 {
    div.App[data-is-mobile='true'] {
        .flex-#{$i} {
            flex: 1 1 100%;
        }
    }

    .flex-#{$i} {
        flex-grow: #{$i};
        flex-shrink: 1;
        flex-basis: calc(#{$i + '%'} - 20px);
        padding-left: 10px;
        padding-right: 10px;
    }
}

div.AdministratorDashboard,
div.CompanyDashboard,
div.ClientDashboard {
    display: flex;
    flex-wrap: wrap;

    div.flex-1,
    div.flex-2,
    div.flex-3,
    div.flex-4 {
        border: 2px solid theme-color('primary');
        border-radius: 4px;
        margin: 0 10px 10px 10px;
        text-align: center;
    }

    div.flex-1 {
        flex: 1 1 calc(25% - 20px);
        max-width: calc(25% - 20px);
    }

    div.flex-2 {
        flex: 2 1 calc(50% - 20px);
        max-width: calc(50% - 20px);
    }

    div.flex-3 {
        flex: 3 1 calc(75% - 20px);
        max-width: calc(75% - 20px);
    }

    div.flex-4 {
        flex: 4 1 calc(100% - 20px);
        max-width: calc(100% - 20px);
    }

    div.SubmitAllowable,
    div.CompanyStatus {
        flex: 4 1 calc(100% - 20px);
        max-width: calc(100% - 20px);
        border-radius: 4px;

        margin: 0 10px 10px 10px;
        line-height: 2rem;
        font-size: 1.2rem;
    }
}

div.App[data-is-mobile='true'] {
    div.AdministratorDashboard,
    div.CompanyDashboard {
        div.flex-1,
        div.flex-2,
        div.flex-3,
        div.flex-4 {
            flex: 1 1 calc(100% - 20px);
            max-width: calc(100% - 20px);
        }
    }
}

div.display-text {
    &.display-text-input {
        height: 30px;
        background-color: theme-color('light-neutral');
        border-radius: 5px;
        line-height: 30px;
        padding: 0 5px;
        overflow: hidden;

        svg {
            margin-right: 5px;
        }
    }
}

.padded-left {
    padding-left: 15px;
}

.padded-right {
    padding-right: 15px;
}

.padded {
    padding: 15px;
}

dl {
    &.horizontal {
        @extend .clearfix;

        dt {
            float: left;
            clear: left;
            width: 15%;
            font-weight: 700;
            line-height: 18px;
            margin-bottom: 20px;
        }

        dd {
            white-space: pre-line;
            float: left;
            word-wrap: break-word;
            width: 85%;
            margin-inline-start: 0;

            clear: right;
            line-height: 18px;
            margin-bottom: 20px;
        }
    }

    &.grid {
        overflow: visible;
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        gap: 10px 0;
        grid-auto-columns: minmax(100px, auto);
        grid-auto-rows: minmax(auto, auto);

        dt {
            grid-column: 1/5;
            font-weight: 500;

            &.bold {
                font-weight: 600;
            }
        }

        dd {
            grid-column: 5/12;
            margin-left: 5px;

            &.bold {
                font-weight: 600;
            }
        }
    }

    &.small {
        max-width: 300px;
    }

    &.medium {
        max-width: 600px;
    }

    &.full {
        dd {
            width: 100%;
        }
    }
}

div.App[data-is-mobile='true'] {
    dl {
        &.horizontal {
            dt {
                width: 100%;
            }
        }
    }
}

.clearfix {
    &:after {
        content: '';
        display: block;
        height: 0;
        width: 100%;
        clear: both;
    }
}

a[href^='tel'],
a[href^='mailto']{
    svg {
        margin-right: 5px;
    }
}

form.SubmitApplicationForm {
    div.CardElements {
        max-width: 600px;
        min-width: 250px;

        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0 0 0;
        margin: 20px auto;
        border-radius: 3px;
        border: 1px solid theme-color('dark-neutral');

        h2 {
            width: 100%;
            background-color: theme-color('primary');
            color: theme-color('contrast');
            text-align: center;
            padding: 5px;
        }

        div.StripeElement {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-self: stretch;
            margin: 20px;
        }
    }
}

.wrap {
    label.bp3-label {
        white-space: normal;
        line-height: inherit;
    }
}

.bp3-control.bp3-checkbox {
    margin-top: 0;
}

.Dashboard {
    a {
        color: theme-color('primary');
    }
}

ul.hierarchical-display {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;

    li {
        display: flex;
        height: 47px;
        align-items: center;
        justify-content: space-between;
        line-height: 47px;
        padding-left: 15px;

        &:nth-child(odd) {
            background-color: rgba(0, 0, 0, 0.03);
        }

        &:nth-child(even) {
            background-color: #fff;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.08);
        }
    }
}

.bordered {
    border-width: 1px;
    border-style: solid;

    &.bordered-warning {
        border-color: theme-color('warning');
    }
}

.floating-bottom-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 30px;
    z-index: 5000;
    background: theme-color('warning');
    color: white;

    > .inner {
        max-width: $pageWidth - 30px;
        margin: 0 auto;
        padding: 15px 0;
        @extend .flex-v-center;
    }

    span.title {
        white-space: nowrap;
        padding-right: 10px;
    }
}

iframe { border: 0; }
