@import "/src/scss/variables";
@import "/src/scss/mixins";

.client-requirements {
  display: inline-flex;
  align-items: center;
  font-size: 1.2em;

  &.all-met { color: theme-color('secondary') }
  &.expiring { color: theme-color('warning') }
  &.below { color: theme-color('error') }

  .icon {
    margin-left: 5px;
    width: 25px;
    height: 25px;
  }
}

ul.client-requirements-tooltip {
  padding-left: 20px;

  li {
    &:nth-child(1n) {
      background-size: 20px 20px;
      padding: 0 20px 0 5px;
    }
    &:last-child { padding-bottom: 5px; }
  }
}
