//blueprint stylings
// TODO: please update/remove when new stylings go in effect site-wide
.marlowe-theme {
    .bp3-button, & {
        font-family: "Open Sans", "Helvetica Neue", "Arial", sans-serif;
    }

    .bp3-button.bp3-intent-primary {
        background-color: var(--primary);
    }
    .bp3-button.bp3-intent-primary:hover {
        background-color: var(--primary-hover);
    }

    .bp3-button.bp3-intent-success {
        background-color: var(--success);
        font-weight: bold;
    }
    .bp3-button.bp3-intent-success:hover {
        background-color: var(--success-hover);
    }
}

.marlowe-theme.box-page {
    min-height: 100%;
    min-width: 100%;
    background: var(--app-background) center center no-repeat fixed;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
    color: var(--text-color);
}

.marlowe-footer {
    min-height: 60px;
    width: 100%;
    background: var(--primary);
    color: white;
    padding: 0 20px 10px 20px;
    display: flex;
    align-items: middle;
    justify-content: space-between;
    text-align: center;
    flex-wrap: wrap;

    > * {
        order: 2;
        flex: 1 0 33%;

        @media screen and (max-width: 1050px) {
            flex: 0 0 100%;
        }
    }

    &.fixed {
        position: fixed;
        bottom: 0;
    }

    .wm-logo-wrap { 
        text-align: left;
        padding-top: 5px;
        @media screen and (max-width: 1050px) {
            display: none;
        }
    }
    .links-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 1050px) {
            padding-top: 20px;
            order: 1;
        }
    }
    .cert-wrap { 
        text-align: right;
        @media screen and (max-width: 1050px) {
            display: none;
        }
    }
    img { height: 45px }
    a {
        color: white;
        text-decoration: underline;
        &:hover { color: #ddd; }
    }
}