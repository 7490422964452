@import "/src/scss/variables";

.page.ContactUsPage {
    padding: 0 20px 20vh;

    @media (min-width: $tabletV) {
        padding-bottom: 20vh;
    }

    .page-wrapper {
        max-width: 700px;
        font-size: 1.5em;

        @media (min-width: $tabletV) {
            margin-top: 5vh;
            padding: 60px 80px;
        }
    }
}
