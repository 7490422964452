div.Register {
    min-height: 100%;
    min-width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    div.FlexContainer {
        width: 600px;
        min-width: 250px;

        display: flex;
        flex-direction: column;
        align-items:center;
        background: theme-color("light-neutral-overlay");
        padding: 20px 0 0 0;
        margin: 0 20px;
        border-radius: 3px;

        .RegisterFields, .RegisterLogin {
            display: flex;
            flex-direction: column;
            align-self: stretch;
            padding: 0 20px;
        }

        .RegisterLogin {
            padding-bottom: 20px;
        }
    }
}
