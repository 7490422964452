@import "/src/scss/mixins";
@import "/src/scss/variables";

.QuestionAddEdit {
  .input-choices {
    padding: 0 0 10px 285px;
  }

  .input-choice {
    padding: 3px 5px;
    margin: 5px;
    display: inline-flex;
    background: #f3f3f3;

    button {
      border: 0;
      margin: 0 2px 0 5px;
      padding: 0;
      background: none;
      box-shadow: none;
      font-size: 0.8em;
      cursor: pointer;
      &:hover {
        color: theme-color("warning");
      }
    }
  }
}
