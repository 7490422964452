.SupplyChainAnalyticsPage {
    min-height: 700px;

    .supply-chain {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    .supply-chain-card {
        width: 560px;
        max-width: none;
        flex: 0 0 auto;
        margin: 20px;
        min-height: 350px;
    }

    .info-card-header h2 {
        display: flex;
    }

    .info-card-body h3 {
        font-weight: normal;
    }

    .category-filter-widget {
        z-index: 2;
        display: flex;
    }
}
