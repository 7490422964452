.suspension-reason-modal {
  &.bp3-dialog {
    padding-bottom: 0;
  }
  div.bp3-form-group.bp3-inline.form-fill label.bp3-label {
    width: 100px;
  }
}

.bp3-dialog.dialog-form {
  width: 650px;

  .dialog-form {
    div.bp3-form-group.bp3-inline.form-fill {
      label.bp3-label {
        width: 195px;
      }
    }
  }

  .fileFieldError {
    margin-left: 200px;
  }
}

.bp3-dialog.email-invitee {
  div.bp3-form-group.bp3-inline.form-fill {
    label.bp3-label {
      width: 150px;
    }
  }
}

.footer-btn-container {
  width: 100%;
}

.bp3-dialog.edit-client-category-dialog {
  width: 650px;

  .dialog-form {
    div.bp3-form-group.bp3-inline.form-fill {
      label.bp3-label {
        width: 195px;
      }
    }
  }
}

.bp3-dialog.form-content {
  padding-bottom: 0;

  .bp3-form-group.form-fill {
    flex-wrap: wrap;
    margin-top: 20px;

    label.bp3-label {
      width: auto;
    }
    .bp3-form-content {
      flex: 0 0 100% !important;
      margin: 10px 0;
    }
  }
}

.invitee-update-scrollable {
  overflow-y: auto;
  max-height: 300px;
  .invitee-update {
    dd {
      display: block;
      margin-inline-start: 5px;

      word-wrap: break-word;
      white-space: pre-wrap;
      word-break: break-word;

      margin-bottom: 10px;

      &.invtee-update-header {
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }
}

.invitee-update-flex {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
}

.invitee-update-popup {
  padding: 8px;

  .bold {
    font-weight: bold;
  }
}
