@import "/src/scss/_variables";

.app-background {
    height: 100vh;
    width: 100vw;

    position: fixed;
    top: $headerMenuHeight;

    background: var(--app-background) center center no-repeat fixed;
    background-size: cover;
    z-index: -100;
}
