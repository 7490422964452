@import '/node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '/node_modules/@blueprintjs/core/lib/css/blueprint.css';
@import '/node_modules/@blueprintjs/table/lib/css/table.css';
@import '/node_modules/@blueprintjs/datetime/lib/css/blueprint-datetime.css';
@import '/node_modules/@blueprintjs/popover2/lib/css/blueprint-popover2.css';

@import '/node_modules/@blueprintjs/core/src/common/variables';
@import '/node_modules/@blueprintjs/core/src/common/colors';

$pt-intent-primary: theme-color('primary');
$pt-intent-primary-hover: theme-color('primary');
$pt-intent-primary-active: theme-color('primary');

$button-intents: (
    'primary': (
        theme-color('primary-dark'),
        theme-color('primary'),
        theme-color('primary-darker'),
    ),
    'success': (
        $pt-intent-success,
        $green2,
        $green1,
    ),
    'warning': (
        $pt-intent-warning,
        $orange2,
        $orange1,
    ),
    'danger': (
        $pt-intent-danger,
        $red2,
        $red1,
    ),
) !default;

@import '/node_modules/@blueprintjs/core/src/reset';
@import '/node_modules/@blueprintjs/core/src/typography';
@import '/node_modules/@blueprintjs/core/src/components/index';

a,
span.link {
    color: theme-color('secondary');
    &:hover {
        color: theme-color('secondary-dark');
    }
}

@import 'blueprint/form';
@import 'blueprint/button';
@import 'blueprint/card';
@import 'blueprint/breadcrumbs';
@import 'blueprint/tabs';
@import 'blueprint/menu-and-dropdowns';

div.Main {
    color: theme-color('dark-neutral');

    h1 {
        font-size: 1.5em;
    }
    h2 {
        font-size: 1.3em;
    }
    h3 {
        font-size: 1.1em;
    }
    h4 {
        font-size: 0.9em;
    }
}

div.Paginator {
    margin-top: 10px;
    text-align: center;
    z-index: 1;

    .active {
        background-color: theme-color('primary');
        color: theme-color('secondary-light');
    }

    .bp3-button {
        padding: 5px 20px;
        min-width: 0;
    }
}

.bp3-callout {
    z-index: 10;
}

div.intent {
    &.intent-primary {
        background-color: theme-color('primary');
        color: theme-color('contrast');
    }

    &.intent-success {
        background-color: theme-color('secondary');
        color: theme-color('dark-neutral');
    }

    &.intent-warning {
        background-color: theme-color('warning');
        color: theme-color('contrast');
    }

    &.intent-danger {
        background-color: theme-color('danger');
        color: theme-color('contrast');
    }

    &.intent-info {
        background-color: theme-color('info');
        color: theme-color('contrast');
    }
}

.padded-icon {
    padding: 5px;
    cursor: pointer;
}
