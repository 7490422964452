.prosure-key-assessments-panel {
    margin: -20px;

    .bp3-card {
        border: none;
        padding: 0;
        box-shadow: none;
        margin: 10px 0;
        background-color: transparent;

        &.not-active {
            img {
                filter: grayscale(100%);
            }
            h2 {
                color: #aaa !important;
            }
        }

        &.large {
            display: flex;
        }

        .logo {
            flex: 0 1 33.33%;
            line-height: 32px;
            text-align: left;

            img {
                margin-left: 15px;
                width: 80px;
            }
        }

        .content {
            display: flex;
            flex-flow: column;
            justify-content: left;

            h2 {
                font-size: 1.3rem !important;
                line-height: 1 !important;
                color: #333 !important;
                text-align: left !important;
                font-weight: 400;
                margin: 0;
            }

            p {
                font-size: 0.65rem;
                color: #333;
                margin: 0;
                line-height: 1;
                text-align: left;
            }

            div.moreDetails {
                font-size: 1rem;
                color: #7bbb4c;
                line-height: 1;
                text-align: left;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
