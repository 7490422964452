@import "scss/variables";
@import "scss/mixins";

.exhibition-signup-page {
  min-height: 100%;
  min-width: 100%;
  padding: 50px 0;
  max-width: 500px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: visible;
  overflow-y: hidden;
  background: url("/scss/assets/login-background-3.jpg") white center center fixed;
  background-size: cover;

  img {
    width: 100%;
    max-width: 280px;
  }

  > .signup-card {
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    z-index: 1;

    display: flex;
    align-items:center;
    flex-flow: column;
    justify-content: center;
    background: theme-color("contrast");
    padding: 20px;
    margin: 0 20px;
    border-radius: 3px;

    button {
      margin: 40px 0 20px;
    }
  }
}
