.multi-level-switch {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 0.0001;
  transition: flex-grow 1000ms linear;

  .item-label {
    padding: 0px;
  }

  .single {
    background: none;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &.parent {
    margin-bottom: 12px;
  }

  .title {
    padding-left: 20px;
    align-items: center;
    display: flex;

    .expand-button {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
    }
  }

  > label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 35px;
    cursor: pointer;
  }

  > .children {
    padding-left: 42px;
    padding-bottom: 1px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 150ms;
  }

  &.showChildren {
    flex-grow: 1;

    > label {
      padding: 12px 0px;
    }

    > .children {
      max-height: 500px;
      transition: max-height 350ms;
    }
  }

  &.parent {
    button + span {
      margin-left: 12px;
    }
    >label {
      border-bottom: 1px solid rgba(0,0,0,0.05);
      padding: 0px 0px 8px 0px;
    }
  }
}
