.decline-button {
  margin-right: 20px;
}

.invitation-requirements {
  margin: 15px 0 30px 30px;

  span {
    font-weight: bold;
    margin-right: 10px;
  }
}
