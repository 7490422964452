@import "/src/scss/variables";
@import "/src/scss/mixins";

button.add-to-supply-chain {
  display: inline-block;
  width: auto;
  background: none !important;
  border-radius: 500px;
  padding: 5px 8px;
  color: theme-color("error") !important;
  font-size: 30px;

  &:hover {
    background: rgba(0,0,0,0.1) !important;
  }
}

div.bp3-form-group.required > label:not(.bp3-file-input) {
  text-decoration: none;
}

.bp3-dialog.add-supply-chain-dialog{
  width: 650px;

  .dialog-form {
   div.bp3-form-group.bp3-inline.form-fill {
       label.bp3-label {
           width: 195px;
       }
   }
 }

 .fileFieldError  {
   margin-left: 200px;
 }
}
