.bp3-button {
    margin-bottom: 5px;
    border-radius: 2px;
    background-image: none !important;
    box-shadow: none !important;

    &.bp3-large {
        padding: 15px 30px;
        min-width: 150px;
    }
}

.bp3-button:not([class*="bp3-intent-"]) {
    background-color: #dbdbdb;
    color: theme-color('dark-neutral');

    &:hover {
        background-color: theme-color('light-neutral');
    }
}

.cardRemove {
    float: right;
    margin-top: -2px;
}

button.clear-button {
  border: 0;
  background: none;
  cursor: pointer;

  &.success {
    color: theme-color("secondary");
  }

  &.underline {
    text-decoration: underline;
  }

  &:hover {
    text-decoration: underline;
  }
}
