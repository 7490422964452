:root {
    --primary: #006273;
    --primary-hover: #004a57;
    --success: #7BBB4C;
    --success-hover: #5d9436;
    --text-color: #525252;
    --error: #ff0000;

    --app-background: url("/scss/assets/login-background-3.jpg") #065a68;

    // box
    --box-bg: white;
    --box-footer-bg: var(--primary);
    --box-footer-text: white;
    --box-footer-text-hover: #85c0ca;
    --reset-button-color: var(--primary);
    --reset-button-hover-color: var(--primary-hover);
    --create-account-bg: #eee;
    --box-input-border-color: #cccccc;
}