@import '/src/scss/variables';
@import '/src/scss/mixins';

.donut-chart {
  padding: 20px;

  .chart-tooltip {
    position: absolute;
    visibility: hidden;
    border-radius: 5px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.05);
    z-index: 99999;
  }

  svg {
    font-size: 50px;
    position: relative;

    text {
      transform: translateY(15px);
    }
  }
}

.donut-chart,
.bar-chart {
  .hover {
    transition: all 500ms ease;
    cursor: pointer;
    filter: brightness(100%);
  }
  .hover:hover {
    transition: all 150ms ease;
    filter: brightness(115%);
  }
}

.line-chart {
  svg {
    font-size: 0.9rem;
    position: relative;

    g.data-line {
      path {
        stroke-width: 3px;
        fill: none;
        stroke: theme-color('secondary');
      }

      circle {
        fill: theme-color('secondary');
      }

      text {
        text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.5),
          1px -1px 0 rgba(255, 255, 255, 0.5),
          -1px 1px 0 rgba(255, 255, 255, 0.5),
          1px 1px 0 rgba(255, 255, 255, 0.5);
      }
    }
  }
}
