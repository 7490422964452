.multi-level-checkbox {
  .title {
    padding-left: 10px;
    span { vertical-align: middle; }
  }
  > label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 35px;
    cursor: pointer;
  }

  &.root:not(.parent) { padding-left: 42px; }

  > .children {
    padding-left: 42px;
    display: none;
  }

  &.showChildren {
    margin-bottom: 2px;
    background: rgba(0,0,0,0.05);
    > label {
      border-bottom: 1px solid rgba(0,0,0,0.05);
    }
    > .children { display: block; }
  }

  &.parent {
    button + span { padding-left: 8px; }
  }

  .bp3-control {
    margin: 0;
    padding: 10px;
    .bp3-control-indicator { margin: 0 !important; }
  }
}
