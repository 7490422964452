@import "/src/scss/variables";
@import "/src/scss/mixins";

.file-upload-wrap {
  width: 100%;
  position: relative;
  max-height: 450px;
  overflow: scroll;

  .file-list {
    margin: 5px 0;
    display: flex;
    flex-flow: row wrap;
  }

  .file-upload-loading {
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 400px;
    padding: 5px;
    background: #f5f5f5;

    > div {
      padding-top: 10px;
      font-size: 30px;
    }
  }
}

.file-upload {
  width: 100%;
  text-align: center;
  padding: 5px;
  background: #f5f5f5;
  cursor: pointer;

  &:hover {
    background: theme-color("secondary-lighter");
  }

  .drag-frame {
    padding: 2px 25px;
  }

  .dragging-frame {
    padding: 19px 25px;
    min-width: 236px;
    background: theme-color("secondary-lighter");
    border: 1px dashed #bebebe;
  }
  .file-upload-error { color: theme-color("error") }
}

.file-upload-card {
  padding: 10px 40px 10px 20px;
  background: #fafafa;
  margin: 5px 0;
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 100%;

  &:hover { background: theme-color("primary-light") }
  &.uploading {
    background: theme-color("primary-light");
  }
  &.has-errors {
    background: theme-color("error-light");

    button {
      background: theme-color("error");
      color: white;
      &:hover {
        background: theme-color("danger");
      }
    }
  }

  .file-icon {
    width: 20px;
    margin-right: 10px;
  }

  h3 {
    margin: 0;
    min-width: 150px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bp3-popover2-target {
    margin-left: auto;
    cursor: pointer;
    padding: 3px 10px;
    background: theme-color("error");
    color: white;
  }

  button {
    display: inline-flex;
    width: auto;
    border: 0;
    padding: 5px 10px;
    margin-left: auto;
    cursor: pointer;
    min-width: 90px;

    &:hover { background: #cdcdcd }
    .icon { margin-right: 5px; width: 13px; }
    .LoadingIndicator {
      font-size: 12px;
      margin-right: 5px;
    }

    + .bp3-popover2-target { margin-left: 10px }
  }

  .close-button {
    padding: 5px;
    color: inherit;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);

    &:hover { color: red }
  }
}
