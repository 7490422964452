@import '/src/scss/variables';
@import '/src/scss/mixins';

.bp3-card.supply-chain-card {
    display: flex;
    width: 100%;
    background: white;
    text-decoration: none;
    margin-bottom: 10px;
    box-shadow: 2px 2px 5px 0 #ddd;
    color: inherit;
    position: relative;
    transition: background 350ms $linearOutSlowInInterpolator;

    &:hover {
        background: theme-color('secondary-lighter');
    }

    .leave-sc-button {
        color: red;

        .bp3-icon {
            margin: 0;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0);
            color: red;
            text-decoration: underline;
        }
    }

    .supply-chain-card-title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .add-to-supply-chain {
        margin-left: auto;
        display: inline-block;
        width: auto;
        padding: 9px 8px;
        svg {
            margin: 0;
        }
    }

    .company-heading {
        margin: 0 10px 0 0;
        line-height: 1.2;
        font-size: 1.4em;
        padding-right: 260px;
    }

    div.logo {
        flex: 0 0 150px;
        width: 150px;
        height: 150px;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        img {
            width: 100%;
            max-width: 150px;
            max-height: 150px;
        }

        + .company-info {
            padding-left: 30px;
        }
    }

    .company-info {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        padding-left: 165px;
        width: 100%;
        min-height: 150px;
    }

    .company-details {
        flex: 1 1 100%;
    }

    .completed-assessments {
        display: flex;

        > div {
            margin-right: 10px;
        }

        .logo {
            width: 100%;
            max-width: 60px;
            max-height: 60px;
        }
    }

    div.actions {
        position: relative;
        z-index: 2;
        order: 2;
        flex: 1 0 auto;
        display: flex;
        align-self: flex-start;
        align-items: center;

        .client-requirements {
            margin-right: 10px;
        }
        .add-to-supply-chain {
            font-size: 25px;
            margin: 0;

            &.invite-text {
                font-size: 14px;
                border-radius: 50px;
                padding: 5px 15px;
            }
        }
    }
}

.dialog-footer-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.grey {
    color: theme-color('neutral');
}
