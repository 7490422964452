.multi-level-section {
  align-self: stretch;
  width: 100%;
  overflow: hidden;

  .section {
    position: relative;

    + .section:before {
      border-top: 1px solid rgba(0,0,0,0.05)
    }

    &:before {
      background: rgba(0,0,0,0.05);
      content: "";
      top: 0;
      left: -500px;
      right: 0;
      bottom: 0;
      position: absolute;
    }

    &.barren {
      display: flex;
      cursor: pointer;

      &:hover {
        &:before {
          background: rgba(0,0,0,0.1);
        }
        span:after {
          opacity: 0.5;
        }
      }

      &:active {
        &:before {
          background: rgba(0,0,0,0.15);
        }
        span:after {
          opacity: 0.5;
        }
      }
    }

    > span {
      display: block;
      font-size: 14px;
      font-weight: 600;
      padding: 8px 15px;
      width: 100%;
      position: relative;
      z-index: 5;

      &:after {
        content: "Click to copy to clipboard";
        display: inline-block;
        position: absolute;
        right: 10px;
        opacity: 0;
      }
    }

    > .children .section {
      padding-left: 20px;
    }
  }
}
