@import '/src/scss/variables';
@import '/src/scss/mixins';

.search-widget {
    background: #fafafa;
    padding: 3px;
    border-radius: 500px;
    margin: 0 auto;
    display: inline-flex;
    position: relative;
    box-shadow: 0 0 30px 2px rgba(255, 255, 255, 0.4);

    form {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        justify-content: stretch;
        align-items: center;

        .bp3-form-group.bp3-inline.form-fill {
            display: block;
            margin: 0;
            padding: 0 25px;
            border-radius: 500px;

            label.bp3-label {
                width: auto;
                font-size: 0.8em;
            }
        }
        .bp3-input {
            box-shadow: none;
            padding: 0;
            border-radius: 0;
            width: auto;
            min-width: 280px;
            font-weight: 700;
            background: none;
            height: 20px;

            &.bp3-multi-select {
                min-width: 150px;
            }
        }

        .multi-select-container .bp3-input {
            min-width: 150px;
        }

        .LoadingIndicator {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            margin: 0;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.05);
            backdrop-filter: blur(3px);
            border-radius: 500px;
            color: white;
        }
    }

    .FilterText {
        flex: 1 1 auto;
    }

    img {
        width: 80px;
    }

    .bp3-control-indicator {
        margin-top: 40% !important;
    }

    + div {
        margin-top: 30px;
    }

    div.buttons {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin: 5px 20px 5px 0;

        .bp3-button.bp3-large {
            border-radius: 500px;
            margin: 0;
        }

        button + button {
            margin-top: 5px;
        }
    }

    div.single-dropdown {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin: 20px 20px 20px 0;
        padding-top: 2px;
    }
}

@media screen and (max-width: 1058px) {
    .search-widget form .bp3-input {
        min-width: 100%;
    }
}

@media screen and (max-width: $tablet) {
    .search-widget {
        padding: 20px 60px;
        border-radius: 125px;
        max-width: 560px;

        form {
            padding: 20px;

            .bp3-button {
                margin: 0 auto !important;
            }
        }

        form .bp3-form-group.bp3-inline.form-fill {
            padding: 5px 25px;
        }

        .FilterText {
            width: 100%;
        }

        .multi-select-container {
            width: 50%;
        }

        .switch-container {
            width: 50%;
        }

        .buttons {
            width: 100%;
            justify-content: center;
        }
    }
}
