.users_container {
    max-height: 235px;
    overflow: scroll;
    background-color: white;
    padding: 8px;
    border-radius: 5px;
}

.user {
    margin-bottom: 2px;
    font-style: italic;
}
