@import '/src/scss/variables';
@import '/src/scss/mixins';

.SearchPage {
  margin-top: 30vh;
  transition: all 350ms $fastOutSlowInInterpolator;

  &.reduced {
    margin-top: 10vh;
  }

  .search-results-widget {
    z-index: 2;
    display: flex;
  }

  .multi-select-container {
    .bp3-form-content {
      height: 20px;
    }

    .bp3-tag-input
      .bp3-tag-input-values:first-child
      .bp3-input-ghost:first-child {
      padding-left: 0;
    }

    .bp3-input-ghost {
      font-weight: 700;
    }
  }

  label.bp3-control.bp3-switch {
    margin: 0;
  }

  .bp3-popover-wrapper {
    position: relative;

    .bp3-overlay {
      position: absolute;

      top: unset;
      width: auto;
      right: unset;
    }
  }

  .grayscale {
    filter: grayscale(100%);
  }

  .text-shadow {
    text-shadow: 1px 1px 5px white, 1px 1px 30px rgba(255, 255, 255, 0.5);
  }

  .search-results {
    transition: height 350ms $fastOutSlowInInterpolator,
      transform 350ms $fastOutSlowInInterpolator,
      padding-top 350ms $fastOutSlowInInterpolator;
    transform: translateY(0);
    height: auto;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10vh;
    background: rgba(255, 255, 255, 0.3);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.45) 0%,
      rgba(255, 255, 255, 0.9) 15vh,
      rgba(255, 255, 255, 1) 30vh,
      rgba(255, 255, 255, 1) 100%
    );
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    flex-flow: row wrap;
    justify-content: stretch;

    &:empty {
      transition: none;
      height: 0;
      padding: 0;
      overflow: hidden;
      transform: translateY(-30px);
    }
  }

  .no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 1.4em;
    height: 80px;
  }

  .search-results-header {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    flex: 0 0 100%;
  }

  .page-title {
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1;
    z-index: 1;
    padding: 0;
    margin: 0;
    color: #333;
    padding-left: 20px;
  }

  .search-results-sort {
    margin-left: auto;
    width: auto;
    display: flex;
    align-items: center;

    > span {
      display: inline-block;
      padding-right: 5px;
      font-size: 1.2em;
      color: black;
      font-weight: normal;
      white-space: nowrap;
    }

    select {
      border: 0;
      padding: 10px 25px 10px 10px;
      background: white;
      height: auto;
      position: relative;
      width: auto;
      line-height: 150%;
    }
    .bp3-icon {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .search-results-filters {
    flex: 0 0 100%;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px 0 #ddd;
    border-radius: 5px;
    margin: 0 20px 10px 0;
    background: theme-color('contrast');
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;

    .filter-header {
      padding: 10px;
      border-radius: 5px 5px 0 0;
      background: theme-color('primary-dark');
      color: theme-color('contrast');
      border-bottom: 1px solid theme-color('primary');
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      span {
        font-size: 1.2em;
        font-weight: bold;
        padding-left: 10px;
      }
      button {
        font-size: 1.1em;
      }
    }

    .filter-body {
      padding: 0 20px 20px;
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    // desktop view
    @media all and (min-width: $tablet) {
      flex: 0 0 330px;

      + .search-results-body {
        flex: 0 1 calc(100% - 350px);
      }
    }

    // mobile
    @media all and (max-width: $tablet) {
      margin: 0 20px 20px 0;

      .filter-body {
        width: 330px;
        align-self: center;
      }
    }
  }

  .search-results-body {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: stretch;
    flex: 0 1 100%;
  }

  .search-results-footer {
    margin-top: 4em;
    flex: 0 0 100%;
  }

  .bp3-menu {
    max-height: 250px;
    overflow: auto;
  }
}

.dialog-footer-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grey {
  color: theme-color('neutral');
}
