ul.bp3-breadcrumbs {
  background-color: theme-color("primary-light");

  a.bp3-breadcrumb {
    color: theme-color("primary");
  }
  span.bp3-breadcrumb {
    color: $pt-text-color;
  }
}

.bp3-breadcrumbs {
  background: theme-color("light-neutral-overlay");
  padding: 5px;
  height: auto;
  line-height: 1;

  li .bp3-breadcrumb {
    line-height: 1.4;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    overflow-y: visible;
    white-space: nowrap;
    display: inline-block;
  }
}
