@import "/src/scss/variables";

.tak-card-count {
  font-weight: bold;
}

.task-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.task-message {
  font-size: 1.3rem;
  max-width: 65%;
}


@media screen and (max-width: $mobileV) {
  .task-message {
    font-size: 1rem;
    max-width: 60%;
  }
}
