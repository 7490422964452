

.floating-bottom-banner.view-as-client {
  .search-wrap {
    width: 300px;
  }

  .bp3-input {
    background: none;
    box-shadow: none;
    border-bottom: 2px solid white;
    border-radius: 0;
    color: yellow;
    &::placeholder {
      color: yellow;
    }
  }
  .bp3-popover-target {
    padding: 0;
  }
}
