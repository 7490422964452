.bp3-dialog.invoice-dialog{
   width: 650px;

   .dialog-form {
    div.bp3-form-group.bp3-inline.form-fill {
        label.bp3-label {
            width: 195px;
        }
    }
  }

  .fileFieldError  {
    margin-left: 200px;
  }
}
