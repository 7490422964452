@import '/src/scss/mixins';
@import '/src/scss/variables';

.comment-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 125px;

    &.show {
        overflow: visible;
        white-space: normal;
        word-break: break-all;
    }
}

.refund-modal {
    &.bp3-dialog {
        padding-bottom: 0;
    }

    div.bp3-form-group.bp3-inline.form-fill label.bp3-label {
        width: 150px;
    }
}

.AssessmentTypesList {
    .clear-button {
        padding: 0;
    }
}

.sla_met {
    color: theme-color('success');
}

.sla_failed {
    color: theme-color('error');
}

.other-refund-reason {
    padding-left: 8px;
}
