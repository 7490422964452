p + h3.bp3-heading,
dl + h3.bp3-heading,
p + .buttons {
    margin-top: 40px;
}

p:empty {
    min-height: 50px;
}

.thank-you-message {
    text-align:center;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 100px 0;
    p {
        margin-bottom: 20px;
    }
}

.payment-form {
  input,
  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }

  @keyframes fade {
    from {
      opacity: 0;
      transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
      opacity: 1;
      transform: scale3D(1, 1, 1);
    }
  }

  .form-group {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    will-change: opacity, transform;
  }

  .form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
  }

  .form-row-label {
    width: 15%;
    min-width: 70px;
    padding: 11px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input {
    border-bottom: 1px solid black;
  }

  @keyframes void-animation-out {
    0%,
    to {
      opacity: 1;
    }
  }
  .form-row-input:-webkit-autofill {
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
  }

  .form-row-input {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    background-color: transparent;
    animation: 1ms void-animation-out;
    line-height: 1.5;
  }

  .form-row-input::placeholder {
    color: #87bbfd;
  }

  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  .StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
    border-bottom: 1px solid black;
  }

  .submit-button {
    display: block;
    font-size: 16px;
    width: calc(100% - 30px);
    height: 40px;
    margin: 30px 15px 0;
    background-color: #24b47e;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #159c6b;
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all 100ms ease-in-out;
    will-change: transform, background-color, box-shadow;
  }

  .submit-button:active {
    background-color: #d782d9;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
      inset 0 1px 0 #e298d8;
    transform: scale(0.99);
  }

  .submit-button.submit-button--error:active {
    transform: scale(0.99) translateY(15px);
  }

  .submit-button:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #7795f8;
    box-shadow: none;
  }

  .form-card {
    text-align: center;
    border: 1px solid;
    background: #fafafa;
    border-radius: 3px;
    padding: 20px 5px;
    margin: 10px 15px;
  }
}

.payment-wrapper {
  max-width: 600px;
}
