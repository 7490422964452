@import "/src/scss/_variables";
@import "/src/scss/mixins";

div.verify {
  min-height: 100%;
  min-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  div.FlexContainer {
    width: 100%;
    max-width: 700px;
    height: 400px;
    box-sizing: border-box;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: theme-color("contrast");
    padding: 20px;
    margin: 0 20px;
    border-radius: 3px;
  }

  .logo {
    justify-content: center;
    align-items: center;
    max-width: 300px;

    img {
      width: 100%;
      max-width: 280px;
    }

    h2 {
      width: 100%;
      text-align: center;
      padding-top: 10px;
      margin-top: 20px;
      border-top: 1px solid theme-color('light-neutral');
      color: theme-color('neutral');
      font-weight: normal;
    }
  }

  .bp3-card {
    width: 90%;
    max-width: 300px;
    height: auto;
    margin: 2em 0;
  }
}
