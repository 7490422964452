.contact-tooltip {
    overflow: hidden;
}

.bold {
    font-weight: bold;
}

.update-info-clickable { 
    cursor: pointer;
}

.update-info-text {
    margin-bottom: 2px;
}