.analytics-widget-card {
    display: flex;
    flex-direction: column;

    background-color: white;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 0 #ddd;

    width: 560px;
    max-width: none;
    min-height: 350px;
    padding: 20px;
    flex-grow: 50;
}