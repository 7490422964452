.integrations-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.suggested-links {
  padding-top: 20px;
  display: flex;
  flex-flow: row wrap;

  .suggested-link-row {
    width: 100%;
    flex: 1 1 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border: 1px dashed #ddd;

    &:not(:last-child) {
      border-width: 1px 1px 0 1px;
    }
  }

  .bp3-input-group {
    width: 100%;
    max-width: 400px;
  }
}
