@import "/src/scss/variables";
@import "/src/scss/mixins";

.topic-buttons {
    flex: 0 1 auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 42px;

    button {
        margin-left: 10px;
    }
}

.topic-input-group {
    display: flex;

    .input-fields {
      flex: 0 1 80%;
    }

    .bp3-input {
      display: block;
      width: 100%;

      + .bp3-input { margin-top: 10px; }
    }

    .buttons {
        margin-left: auto;

        button {
            margin-left: 10px;
        }
    }
}

.topic-drag-handle {
  padding: 10px;
}

.topic-header-text {
    flex: 0 1 100%;

    h3, p {
        padding: 0;
        margin: 0;
        line-height: 1.2;
        vertical-align: top;
    }

    h3 {
        line-height: 38px;
    }
}

.question-add {
    display: flex;

    .bp3-form-content {
        flex: 0 1 80%;
    }

    .buttons {
        margin-left: auto;

        button {
            margin-left: 10px;
        }
    }
}

.AssessmentTypeList {
    .historyFields span {
       font-style: italic;
       font-weight: bold;
    }
}

.AssessmentTypeSLAs {
  .bp3-label {
    font-weight: normal;
  }

  p.client-sla-green {
    color: #98eb34;
  }

  p.client-sla-light-blue {
    color: #3493eb;
  }

  p,
  .sla-input-label {
    font-size: 14px;
  }

  .positive-numeric-input-group {
    display: inline-flex;
    flex: 1 1 auto;
    width: 100%;

    input {
      text-align: center;
    }

    .sla-input-label {
      width: 100px;
      text-align: right;
      padding-right: 5px;
      vertical-align: middle;
      line-height: 30px;
    }

    .bp3-numeric-input + span.sla-input-label {
      margin-left: 25px;
    }
  }
}

.AssessmentTypeDetails {
  .assessment-type-header-container {
    display: flex;
    flex-flow: row;

    .bp3-label {
      overflow: visible;
    }

    .assessment-type-version {
      padding-top: 10px;
      padding-left: 20px;

      .html-select-right-element {
        padding-left: 20px;
      }
    }

    .assessment-type-version-status {
      color: #ffffff;
      padding: 5px 20px;
      border-radius: 3px;
      vertical-align: middle;
      min-height: 30px;

      &.active {
        background-color: theme-color('success');
      }

      &.draft {
        background-color: theme-color('warning');
      }

      &.inactive {
        background-color: theme-color('danger');
      }
    }
  }
}

.AssessmentTypeSummary {
  .bp3-heading {
    .bp3-button {
      margin-left: 10px;
    }
  }
}
