@import '/src/scss/variables';
@import '/src/scss/mixins';

@keyframes flip-in {
  from {
    transform: translate3d(10px, -10px, 10px) rotateX(5deg) rotateY(5deg);
    opacity: 0;
  }
  30% {
    transform: translate3d(10px, -10px, 10px) rotateX(5deg) rotateY(5deg);
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  to {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
    opacity: 1;
  }
}

.info-card {
  flex: 1 1 450px;
  margin: 20px;
  height: auto;
  padding: 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.45) 0%,
    rgba(255, 255, 255, 0.9) 15vh,
    rgba(255, 255, 255, 1) 30vh,
    rgba(255, 255, 255, 1) 100%
  );
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-flow: column nowrap;
  transform-style: preserve-3d;
  position: relative;
  animation: 1000ms flip-in ease-out;

  &.large {
    flex: 1 1 900px;
  }

  &.full-width {
    flex: 1 1 1430px;
  }
}

.info-card-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1em;

  h1,
  h1.title,
  h2.title {
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1;
    z-index: 1;
    padding: 0;
    margin: 0;
    color: #333;
  }
}

.info-card-body {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
}

.info-card-link {
  text-decoration: none;
  display: block;

  &:hover {
    background: theme-color('primary-light');
  }
}

.info-card-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  background: rgba(255, 255, 255, 0.4) 0%;
  color: inherit;
  text-decoration: none;
  margin-bottom: 10px;
  box-shadow: none;
  border: 0;
  transition: background 350ms $linearOutSlowInInterpolator;

  h2 {
    font-size: 10em;
    text-align: center;
    line-height: 130px;
    padding: 10px 0;
    text-decoration: none;
    color: theme-color('primary');
  }

  h3 {
    font-size: 2.5em;
    text-align: center;
    padding: 10px 0;
    text-decoration: none;
    color: theme-color('primary');
  }

  .more-details {
    align-self: flex-end;
    color: theme-color('secondary');
    margin-top: auto;
    font-size: 1.4em;
    &:hover {
      text-decoration: underline;
    }
  }

  .no-decoration {
    color: theme-color('primary');

    &:hover {
      text-decoration: none;
      span {
        text-decoration: underline;
      }
    }
  }
}

ul.info-card-stats {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin: 0;
  padding: 0;

  > li {
    flex: 1 1 150px;
    max-width: 300px;
    font-size: 2em;
    padding: 5px;
    color: theme-color('primary');

    &.all-met,
    &.green {
      color: theme-color('secondary');
    }
    &.expiring,
    &.amber {
      color: theme-color('warning');
    }
    &.below,
    &.red {
      color: theme-color('error');
    }

    a {
      &.all-met,
      &.green {
        color: theme-color('secondary');
      }
      &.expiring,
      &.amber {
        color: theme-color('warning');
      }
      &.below,
      &.red {
        color: theme-color('error');
      }
    }
  }

  h2.stat {
    font-size: 8rem;
    color: inherit;
    padding: 10px 0 0;
  }

  .stat-text {
    display: block;
    text-align: center;
    padding-bottom: 30px;
  }
}
