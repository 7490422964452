@media screen and (max-width: $mobileV) {
    .PreAuthContainer {
        .box {
            margin: 12px;

            .box-logo {
                max-width: 300px;
            }
        }

        .box-body .box-body-inner {
            padding: 20px 32px 30px;
        }

        .box-body-inner .create-account {
            margin: 0px -32px;
        }

    }
}

.box {
    box-sizing: border-box;
    border-radius: 25px;
    box-shadow: 0 0 15px rgba(0,0,0,0.3);
    width: 100%;
    max-width: 530px;
    margin: 50px;

    .box-body {
        border-radius: 25px 25px 0 0;
        padding-top: 50px;
        background: var(--box-bg);
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;
    }

    .box-logo {
        max-width: 350px;
    }

    .box-body-inner {
        padding: 20px 75px 30px;
        width: 100%;
    }
    .bp3-form-group {
        .bp3-form-content {
            flex-wrap: wrap;

            > input,
            > textarea,
            > .html-select-wrap .bp3-html-select {
                width: calc(100% - 23px);

                + span { padding-left: 5px; }
            }
        }
        .bp3-popover-wrapper {
            width: calc(100% - 23px);

            + span { padding-left: 5px; }
        }
        .bp3-input-left-container {
            top: 7px;
            padding-right: 5px;
        }
        input,
        textarea {
            display: block;
            width: 100%;
            border: solid var(--box-input-border-color);
            border-width: 0 0 2px 0;
            border-radius: 0;
            box-shadow: none !important;
            padding: 5px 0 3px;
            font-family: inherit;
            background: none;
            outline: none;
            transition: all 250ms ease;

            &::placeholder {
                font-size: 90%;
            }

            &.isTouched:invalid,
            &.isDirty:invalid {
                border-color: var(--error);
                ~ .input-error { opacity: 1; }
            }
        }
        .bp3-input-group.error input.isTouched,
        .bp3-input-group.error input.isDirty {
            border-color: var(--error);
            ~ .input-error { opacity: 1; }
        }
        textarea {
            resize: vertical;
            min-height: 70px;
            width: calc(100% - 27px) !important;
        }
        .input-error {
            opacity: 0;
            font-size: 80%;
            color: var(--error);
        }
        label {
            display: block;
            padding-bottom: 3px;
            font-size: 80%;
            line-height: 20px;
        }
        svg {
            color: var(--box-input-border-color);
            &:hover { color: inherit; }
        }
    }
    .form-buttons .bp3-form-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            text-decoration: underline;
            color: var(--reset-button-color);
            &:hover { color: var(--reset-button-color-hover) }
        }
        button {
            border-radius: 500px;
            min-width: 100px;
        }
    }

    @media screen and (max-width: $mobileV) {
        h5.bp3-heading.box-sub-heading {
            margin-left: -10px !important;
        }
    }

    .box-sub-heading {
        margin-left: -20px !important;
    }

    .create-account {
        display: flex;
        background: var(--create-account-bg);
        margin: 0 -50px;
        padding: 15px 20px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        > span { white-space: nowrap; padding: 5px; }
        button {
            margin: 0;
            border-radius: 500px;
            min-width: 100px;
            white-space: nowrap;
        }
    }
    button.clear-button {
        color: var(--primary);
        &:hover { color: var(--primary-hover) }
    }
    .box-footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 25px 50px;
        background: var(--box-footer-bg);
        border-radius: 0 0 25px 25px;

        a {
            color: var(--box-footer-text);
            text-decoration: underline;
            &:hover {
                color: var(--box-footer-text-hover);
            }
        }
    }
}