.company-info-flex {
    display: flex;
}

.company-info-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 40px 0px 0px
}

.logo-wrapper {
    width: 100%;
    max-height: 250px;
}

.rating-container {
    margin-top: 50px;
}