@import 'scss/variables';
@import 'scss/info-card';

.DashboardPage {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    max-width: 1490px;
    margin: 5vh auto 10vh;
    transition: all 350ms $fastOutSlowInInterpolator;
    perspective: 2000px;

    .dashboard-card {
        @extend .info-card;
    }

    .dashboard-card-header {
        @extend .info-card-header;
    }

    .dashboard-card-body {
        @extend .info-card-body;
    }

    .dashboard-card-link {
        @extend .info-card-link;
    }

    .dashboard-card-details {
        @extend .info-card-details;
    }

    .account-stats,
    .supply-chain-summary-stats {
        display: table;
        border-spacing: 0px 20px;
        margin-top: -20px;

        > div {
            display: table-row;
            align-items: center;

            h3 {
                display: table-cell;
                vertical-align: middle;
                text-align: left;
                min-width: 50px;
                margin: 10px 0;
                padding: 0 20px 0 0;
                line-height: 35px;
            }

            p {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
                font-size: 1.5em;
                color: #333;
                margin: 0;
                line-height: 1;
                text-align: left;
            }
        }
    }
}
