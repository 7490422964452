div.bp3-tab-list {
    border-bottom: 2px solid theme-color("primary-light");

    div.bp3-tab-indicator-wrapper {
        top: 3px;

        div.bp3-tab-indicator {
            background-color: theme-color("primary");
        }
    }

    /*
    div.bp3-tab-indicator-wrapper {
        border-radius: 5px 5px 0 0;
        background-color: theme-color("secondary") !important;

        div.bp3-tab-indicator {
            display: none;
        }
    }


    .bp3-tab[aria-selected=true] {
        a {
            color: theme-color("contrast");
        }
    }
    */

    div.bp3-tab {
        margin-right: 0;

        a {
          padding: 0 12px 0 10px;
          margin-right: 0;

          svg {
              margin-right: 10px;
          }

          &:hover { color: theme-color("primary-dark") }
        }
    }

    /*
    div.bp3-tab {
        border-top: 1px solid theme-color("primary");
        border-right: 1px solid theme-color("primary");
        border-left: 1px solid theme-color("primary");
        border-bottom: 0px;

        border-radius: 3px;

        padding: 0;

        a {
            padding-left: 5px;
            padding-right: 20px;
        }
    }
    */
}



div.bp3-tabs {
    overflow-x: auto;
    overflow-y: hidden;
}

div.bp3-tabs.parent {
  div.bp3-tab-list {
    border-color: theme-color("secondary-light");
    padding: 0;
  }

  div.bp3-tab-indicator-wrapper div.bp3-tab-indicator {
    background-color: theme-color("secondary-dark");
  }

  div.bp3-tab {
    &[aria-selected=true] {
      color: theme-color("secondary-dark");
    }

    a {
      font-size: 1.2em;

      &:hover { color: theme-color("secondary") }
    }
  }

  + div.TabContent {

    div.bp3-tabs div.bp3-tab-list {
      margin-top: -10px;
    }
  }
}
