@import "/src/scss/mixins";
@import "/src/scss/variables";

.InvitationDetails {
  .files-container {
    .bp3-form-group {
      .bp3-form-content {
        width: unset;

        button {
          margin-left: 10px;
        }

        .bp3-control.bp3-checkbox {
          margin-top: 7px;
        }
      }
    }

    .error-message {
      display: block;
      padding-top: 5px;
      color: theme-color("error");

      &:empty { padding: 0 }
    }
  }

}
