.services-banner {
  border-radius: 10px 10px 0 0;
  padding: 10px 30px;
  background: #f9f9f9;
  display: flex;
  align-items: center;

  .bp3-button {
    border-radius: 500px;
    padding-left: 11px;
    margin: 0;
  }

  .label {
    display: inline-block;
    margin-left: 15px;
  }
}

.services-list ul {
  padding-left: 20px;
  margin: 0;
}
