@import '/src/scss/variables';
@import '/src/scss/mixins';

.DashboardPage.client {
  ul.supply-chain-rating {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;

    li {
      flex: 1 1 150px;
      max-width: 300px;
      font-size: 2em;
      padding: 5px;
      color: theme-color('primary');

      &.all-met {
        color: theme-color('secondary');
      }
      &.expiring {
        color: theme-color('warning');
      }
      &.below {
        color: theme-color('error');
      }

      a {
        &.all-met {
          color: theme-color('secondary');
        }
        &.expiring {
          color: theme-color('warning');
        }
        &.below {
          color: theme-color('error');
        }
      }
    }

    &.assessment-counts li {
      max-width: 415px;
    }

    h2.rating {
      font-size: 8rem;
      color: inherit;
      padding: 10px 0 0;
    }

    .rating-text {
      display: block;
      text-align: center;
      padding-bottom: 30px;
    }
  }
}
