.integration-setup-message {
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    p {
        margin-bottom: 20px;
    }
}
