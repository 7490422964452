@import '/src/scss/_variables';

.App > header {
  position: sticky;
  height: $headerMenuHeight;
  border-top: 5px solid theme-color('secondary');
  width: 100%;
  display: block;
  background: #fff;
  z-index: 3;
  top: 0;
  padding: 0 0 0 30px;

  .header-section {
    display: flex;
    align-items: center;
    height: 100%;

    .Header__navigation_toggle {
      button {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        margin-left: 0.5rem;

        height: 24px;
        width: 30px;

        background: transparent;
        border: none;
        padding: 0;
        box-sizing: border-box;

        cursor: pointer;

        &:focus {
          outline: none;
        }

        .Header__navigation_toggle_line {
          width: 30px;
          height: 2px;
          background: theme-color('dark-neutral');
        }
      }
    }

    .Header__navigation_logo img {
      height: 60px;
      padding-top: 5px;
    }

    &[data-is-mobile='true'] {
      .Header__navigation_logo {
        img {
          width: 150px;
        }
      }
    }

    .Header__navigation_logo {

      @media screen and (max-width: $mobileV) {
        &.client-logo {
          display: none;
        }
      }

      &.client-logo {
        margin-left: 12px;

        img {
          max-width: 250px;
        }
      }
    }

    .Header__navigation_spacer {
      flex: 1;
    }

    .Header__navigation_items {
      margin-right: 10px;

      button.Logout {
        cursor: pointer;
        background: transparent;
        border: none;
        font-size: 1rem;
        height: 50px;

        &:focus {
          outline: none;
        }
      }
    }
  }
}
