.rating-badge-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.large {
    .rating-score {
      font-size: 10rem;
      line-height: 100%;
    }

    .rating-prosure-badge {
      width: 170px;
    }
    .rating-help-icon {
      font-size: 14px;
      svg {
        margin: 4px 10px 5px;
      }
    }
    .rating-total,
    .rating-engagement-label {
      font-size: 130%;
    }
  }

  &.badge {
    max-width: 100px;
    max-height: 55px;
    font-size: 0.65rem;
  }
}

.rating-score {
  font-size: 4rem;
  display: inline-block;
  font-weight: bold;

  &.badge {
    font-size: 2.2rem;
  }
}

.rating-prosure-badge {
  width: 150px;
  margin-bottom: 8px;
}

.rating-engagement-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.rating-engagement-label {
  font-weight: bold;
  margin-right: 8px;
}

.rating-help-icon {
  font-size: 10px;
  border: 1px solid;
  border-radius: 50px;

  svg {
    margin: 3px 8px 4px;
  }
}

.engagement-tooltip {
  display: inline-block;
  height: 100%;
  max-height: 450px;
  max-width: 450px;
  padding: 8px;
  background-color: none;
  overflow-y: auto;
  overflow-x: visible;
}
