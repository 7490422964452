.companyDetails {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-flow: row wrap;
  margin: 0 -10px;

  .bp3-card {
    flex: 1 0 calc(33.33% - 20px);
    margin: 10px;
    min-width: 410px;

    &.large {
      min-width: calc(60% - 20px);
      display: flex;
      flex-flow: row wrap;
    }

    &.borderless {
      box-shadow: none;
      border: 0;
      background: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-flow: column nowrap;

      .bp3-card {
        flex: 1 1 50%;
      }
    }

    .logo {
      align-self: first baseline;
      padding: 60px 40px 0 0;
      max-height: 100%;
      text-align: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    dl {
      flex: 1 0 60%;
      display: flex;
      flex-flow: row wrap;
      justify-content: stretch;
      align-items: stretch;
      min-width: 370px;
      h2 {
        width: 100%;
      }
      dt {
        flex: 0 0 200px;
        padding-right: 10px;
        margin: 0 0 10px;
        font-weight: 700;
      }
      dd {
        flex: 0 0 calc(100% - 200px);
        padding: 0;
        margin: 0 0 10px;
      }
    }

    .completed-assessments {
      display: flex;

      > div {
        margin-right: 10px;
      }

      .logo {
        width: 100%;
        padding: 0;
        max-width: 60px;
        max-height: 60px;
      }
    }

    .is-mobile & {
      flex: 0 1 100%;
      justify-content: center;

      .logo {
        max-width: none;
        padding: 0;
      }
      dl {
        flex: 0 0 100%;
      }
    }
  }

  .credit-health-check-header-container {
    display: flex;
    flex-flow: row wrap;
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
    h2 {
      width: 50%;
      img {
        width: 100%;
        min-width: 200px;
      }
    }

    p {
      flex: 1 0 50%;
      width: 300px;
      padding-left: 10%;
      direction: rtl;
      font-style: italic;
      justify-content: stretch;
      align-items: stretch;
    }
  }

  .rfa-container {
    display: flex;
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
    min-height: 300px;
    align-items: flex-start;

    div.no-data {
      height: 100px;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-items: center;
    }

    div.top-section {
      display: flex;
      justify-content: stretch;
      align-items: stretch;
      flex-flow: row wrap;

      div.rfa-header {
        display: flex;

        .rating {
          flex: 0 0 80px;
          img {
            width: 60px;
          }
        }
        .description {
          flex: 1 1 auto;
        }
      }

      .rfa-metadata {
        display: flex;
      }
    }
  }

  .bp3-tab {
    padding: 2px 15px;
  }
}

.invitee-update-popup {
  padding: 8px;

  .bold {
    font-weight: bold;
  }
}
