@import 'scss/variables';
@import 'scss/mixins';

.vertical-tab {
    border-radius: 10px;
    background: white;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;

    .header-section {
        padding: 20px;
        cursor: pointer;
        color: inherit;
        text-decoration: none;
        background: rgba(0, 0, 0, 0.05);
        transition: all 150ms ease-in;

        h2 {
            margin: 0;
        }

        &:hover {
            background: rgba(0, 0, 0, 0.07);
            .actions {
                text-decoration: underline;
            }
        }
    }

    .title-section {
        flex: 1 1 auto;

        .subtitle {
            color: theme-color('neutral');
            &:not(:empty) {
                padding-right: 15px;
            }
        }
        .success {
            color: theme-color('secondary');
            &:not(:empty) {
                padding-right: 15px;
            }
        }
        .warning {
            color: theme-color('warning');
            &:not(:empty) {
                padding-right: 15px;
            }
        }
        .error {
            color: theme-color('error');
        }
    }

    .actions {
        flex: 0 0 auto;
        margin-left: auto;
    }

    .body-section {
        overflow: hidden;
        max-height: 0;
        padding: 0 20px;
        transition: all 350ms ease-in-out;
    }

    &.open .body-section {
        max-height: 10000px;
        padding: 20px;
    }

    + .vertical-tab {
        margin-top: 20px;
    }
}
