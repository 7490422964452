.image-cell {
  padding: 15px 15px 15px 0;

  img {
    width: 100%;
    max-width: 75px;
    max-height: 75px;
  }
}

img.badge-img {
  width: 100%;
  max-width: 175px;
  max-height: 175px;
}

img.logo-full {
  width: 100%;
  max-width: 250px;
  max-height: 250px;
}

img.icon.small {
  width: 25px;
  height: 25px;
  padding: 5px;
  overflow: hidden;
  vertical-align: middle;
  justify-self: center;
  align-self: center;
}
